import { NextSeo } from "next-seo";
import { useRouter } from "next/router";
import { FRONTEND_HOST } from "@/config/constants";
import { defaultDescription, defaultTitle } from "@/config/seo-config";

const MetaSeo = (props: { title?: string; description?: string; hasQuery?: boolean; keywords?: string[] }) => {
  const router = useRouter()
  const url = props.hasQuery ? `${FRONTEND_HOST}${router.asPath}` : `${FRONTEND_HOST}${router.asPath.split("?")[0]}`;
  // const additionalMetaTags = [];
  // if (props.description) {
  //   additionalMetaTags.push({ name: "description", content: props.description });
  // }
  return (
    <NextSeo {...{
      title: props.title || defaultTitle,
      openGraph: {
        title: props.title || defaultTitle,
        url,
        description: props.description || defaultDescription,
        images: [
          {
            url: "https://d1bwwumizb0ypj.cloudfront.net/favicon.ico",
            width: 32,
            height: 32,
            alt: "Doubtnut Logo"
          }
        ],
      },
      // twitter: {
      //   handle: "@doubtnut",
      // },
      additionalMetaTags: [
        { name: "title", content: props.title || defaultTitle },
        { name: "description", content: props.description || defaultDescription },
        { name: "url", content: url },
        { name: "keyword", content: [...props.keywords ?? [props.title]].join() },
        // ...additionalMetaTags
      ],
      canonical: url,
    }} />
  )
}


export default MetaSeo;

