import IconGeneric from "../SVG";
import { IStatsV2, IStatsV2Item } from "./interfaces";

const StatsV2Item = (props: { item: IStatsV2Item }) => {
  const item = props.item;
  return <li className={`flex flex-col items-center`}>
    <div className="w-10 h-10">
      {item.svg && <span dangerouslySetInnerHTML={{ __html: item.svg }}></span>}
      {(!item.svg && item.icon) && <IconGeneric name={item.icon} className={item.className} />}
    </div>
    <div className="text-center">
      <div className="text-xl font-semibold mt-4">{item.title}</div>
      <p className="text-gray-500 font-medium mt-0">{item.subtitle}</p>
    </div>
  </li>
}

const StatsV2 = (props: IStatsV2) => {
  return <ul className={`list-none grid grid-cols-2 sm:grid-cols-4 gap-y-8 pl-0 ${props.className ?? ""}`}>
    {props.items.map((item, index) => (
      <StatsV2Item key={index.toString()} item={item} />
    ))}
  </ul>
};


export default StatsV2;