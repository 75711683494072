import { CourseCardListItem } from "./CourseCardListItem";
import { ICourseCardList } from "./interfaces";

export const CourseCardList = (props: ICourseCardList) => {
  const { data } = props;

  if (!data || !data.length) {
    return (
      <p className="text-xl font-bold h-[40vh] flex items-center justify-center">
        No Courses Found, Please Widen your filters!
      </p>
    );
  }

  return (
    <div className="flex flex-col gap-8">
      {data.map((item, index) => (
        <CourseCardListItem key={index.toString()} item={item}/>
      ))}
    </div>
  );
};
