import { IResultsData } from "./interfaces";
import Image from "next/image";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Shimmer from "@/utils/shimmer";

const Results = ({ items }: { items?: IResultsData[] }) => {
  const [maxCount, updateMaxCount] = useState(8);

  if (!items?.length) return null;

  return (
    <>
      <div className='flex flex-wrap justify-center justify-items-center gap-x-2 lg:gap-x-4 gap-y-4'>
        {items?.slice(0, maxCount)?.map((data, index) => (
          <div
            className='card flex flex-col min-w-[10rem] max-w-[10rem] shrink-0 text-center p-0 shadow-md	shadow-gray-500'
            key={index}
          >
            {data.rank ? (
              <span className='rounded-t w-full bg-black text-white p-2 pt-4'>
                <span className='text-lg sm:text-3xl font-black'>
                  {data.rank}
                  <sup className='text-sm align-super'>
                    {Number(data.rank) === 1
                      ? "st"
                      : Number(data.rank) === 2
                        ? "nd"
                        : Number(data.rank) === 3
                          ? "rd"
                          : "th"}
                  </sup>
                </span>{" "}
                <span className='font-extrabold'>Rank</span>
              </span>
            ) : (
              <>
                <span className='rounded-t w-full bg-black text-white pt-2 sm:pr-3 text-xl sm:text-3xl font-black'>
                  {(data.marks)?.split("%")[0]}
                </span>
                <span className='text-sm w-full bg-black text-white pb-2 sm:pr-3 sm:text-xl font-black'>{"percentile"}</span>
              </>
            )}

            <div
              className={`bg-[#F6CC41] !leading-4 sm:!leading-normal text-lg sm:text-xl ${!data.rank && "!sm:text-2xl p-2 space-y-2"}`}
            >
              {!!data.rank && (
                <div className='py-1'>
                  <span className='text-black text-xl font-medium'>
                    {data.marks}
                  </span>
                  <hr className='border-1 border-primary my-1 mx-4' />
                </div>
              )}
              <div
                className=''
                data-te-toggle='tooltip'
                title={data.board || data.exam}
              >
                <span className='text-black text-xl font-medium'>{data.board || data.exam}</span>
              </div>
              {data.class && (
                <>
                  {!data.rank && (
                    <hr className='border-b-1 border-primary mx-4' />
                  )}
                  <div className='py-1'>
                    <span className='text-black text-xl font-medium'>{`${data.class?.split("-")[0]} Class`}</span>
                  </div>
                </>
              )}
            </div>
            {data.img_url ? (
              <Image
                src={data.img_url ?? ""}
                width={85}
                height={85}
                style={{ objectPosition: "50% 30%" }}
                className='w-full h-24 sm:h-40 object-cover object-top my-0'
                alt={data.name}
                placeholder='blur'
                blurDataURL={Shimmer(600, 400)}
              />
            ) : (
              <div className='w-full h-24 sm:h-40	bg-bgcard flex justify-center items-center font-bold text-2xl'>
                NO Image Avalaible
              </div>
            )}
            <div className='rounded-b w-full bg-accent text-white text-[12px] font-medium sm:text-xl p-1 whitespace-nowrap overflow-hidden	 text-ellipsis'>
              {data.name}
            </div>
          </div>
        ))}
      </div>
      {
        (maxCount < items.length) && <div className='flex justify-center'>
          <button
            className='underline text-accent mt-4 mx-auto'
            onClick={() =>
              updateMaxCount((previousCount) =>
                isMobile ? previousCount + 3 : previousCount + 5
              )
            }
          >
          View More
          </button>
        </div>
      }
    </>
  );
};

export default Results;
