import { ICourseCardListItem } from "./interfaces";
import FaIndianRupeeSign from "../SVG/Fontawesome/FaIndianRupeeSign";
import LinkWrapper from "@/components/LinkWrapper";
import Shimmer from "@/utils/shimmer";
import Image from "next/image";
import { useRouter } from "next/router";
import DiscountBadge from "../Badge/Discount";

export const CourseCardListItem = (props: {
  item: ICourseCardListItem;
  target?: string;
}) => {
  const { item } = props;
  const router = useRouter();
  const target = props.target ?? "_self";

  return (
    <div className="card p-0 flex flex-col lg:flex-row items-center lg:h-72 grow">
      <LinkWrapper
        className="block no-underline lg:h-full self-stretch shrink-0"
        target={target}
        rel="noopener noreferrer"
        href={`/course/${item.assortment_id}`}
      >
        {/* <div className="flex justify-center items-center h-full"> */}
        <Image
          className="rounded-t-md lg:rounded-l-md lg:rounded-tr-none my-0 lg:h-full w-full"
          src={item.demo_video_thumbnail ?? ""}
          alt={item.title}
          width={384}
          height={216}
          placeholder="blur"
          blurDataURL={Shimmer(384, 216)}
        />
        {/* </div> */}
      </LinkWrapper>

      <div className="md:self-center space-y-5 text-center p-4 h-full grow flex flex-col justify-between">
        <LinkWrapper
          className="no-underline basis-2/3 md:self-center space-y-5 text-center px-2"
          target={target}
          rel="noopener noreferrer"
          href={`/course/${item.assortment_id}`}
        >
          <div>
            <h2 className="my-0">{item.title}</h2>
            <p>
              {item.subtitle}
              {item.exam_category && <span> - {item.exam_category}</span>}
            </p>
            <div className="flex gap-2 text-xs lg:text-sm justify-center">
              <span className="w-max">{item.language}</span>|
              <span className="w-max">{item.start_date} to {item.end_date}</span>
            </div>
          </div>

          <div className="flex gap-2 justify-center items-center tracking-wider">
            <p className="font-bold m-0 text-2xl">
              <FaIndianRupeeSign className="mr-1" />
              {item.display_price}
            </p>
            {item.base_price && <s className="text-base text-disabled">
              <FaIndianRupeeSign className="mr-1" />
              {item.base_price}
            </s>}
            {item.base_price && <DiscountBadge base_price={item.base_price} display_price={item.display_price} className="bg-gray-200 rounded-sm text-green-700 px-1 py-0.5 text-base p-1"/> }
          </div>
        </LinkWrapper>

        <LinkWrapper
          rel={`noopener noreferrer ${item.variant_id ? "noindex nofollow" : ""}`}
          target={target}
          className="block px-4"
          href={
            item.variant_id
              ? `/checkout?variant_id=${item.variant_id}&url=${router.asPath}`
              : `/course/${item.assortment_id}`
          }
        >
          <button className="btn-primary max-w-full w-full lg:w-80">Buy Now</button>
        </LinkWrapper>
      </div>
    </div>
  );
};
